<template>
  <div
    class="hidden lg:block"
    data-el="ui-news-list"
  >
    <UiSliderHorizontal
      classes="swiper-news lg:!-mx-3 lg:!px-3 xl:!-mx-0.5 xl:!px-0.5"
      class="swiper-news"
      :less-sliders-on-view="true"
      :center-insufficient-slides="true"
      :options="sliderOptions"
    >
      <UiNewsListItem
        v-for="listItem in props.list"
        v-bind="listItem"
        :key="listItem.id"
        :labels="labels"
        :size-variant="props.sizeVariant"
        class="h-full"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="{ ...slotData, item: listItem }"
          />
        </template>
      </UiNewsListItem>
    </UiSliderHorizontal>
  </div>

  <div class="flex flex-col lg:hidden xl:gap-6">
    <template
      v-for="(listItem, index) in newsList"
      :key="listItem.id"
    >
      <UiNewsListItemFirst
        v-if="index === 0 && isHorizontalList"
        v-bind="listItem"
        :size-variant="props.sizeVariant"
        class="mb-1 xl:hidden"
        :title-background-class="props.titleBackgroundClass"
      />
      <UiNewsListItem
        v-else
        v-bind="listItem"
        class="block"
        :labels="labels"
        :add-more-info="false"
        :size-variant="props.sizeVariant"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="{ ...slotData, item: listItem }"
          />
        </template>
      </UiNewsListItem>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import UiNewsListItem from '../UiNewsListItem/UiNewsListItem.vue'
import UiNewsListItemFirst from '../UiNewsListItem/UiNewsListItemFirst/UiNewsListItemFirst.vue'
import UiSliderHorizontal from '../../UiSlider/UiSliderHorizontal.vue'
import type { CeNews } from '../../../../rawlplug-cms/components/T3CeNewsPi1/T3CeNews_pi1.types'
import type { UiNewsListItemSizeVariant } from '../UiNewsListItem/UiNewsListItem.types'

interface UiNewsList {
  list: CeNews[]
  isHorizontalList?: boolean
  labels?: {
    readTime: string
  } | null
  sizeVariant?: UiNewsListItemSizeVariant
  titleBackgroundClass?: string | string[]
}

const props = withDefaults(defineProps<UiNewsList>(), {
  isMainHorizontalList: false,
  labels: null,
  sizeVariant: 'comfort',
  titleBackgroundClass: undefined,
})

const newsList = computed(() => {
  return props.isMainHorizontalList ? props.list : props.list.slice(0, 4)
})

const sliderOptions = computed((): SwiperOptions => {
  if (props.sizeVariant === 'dense') {
    return {
      slidesPerView: 5,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          slidesOffsetAfter: 0,
        },
        1300: {
          slidesPerView: 4,
          slidesOffsetAfter: 0,
        },
        1366: {
          slidesPerView: 5,
          slidesOffsetAfter: 0,
        },
      },
    }
  }

  return {
    slidesPerGroup: 2,
  }
})
</script>
